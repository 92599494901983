<template>
  <b-modal
    v-model="isShowModal"
    hide-footer
    hide-header
    centered
    size="xs"
    noFade
  >
    <div class="body-exit text-center">
      <span>กดอีกครั้งเพื่อออก</span>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isShowModal: false
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.body-exit {
  color: white;
  font-size: 12px;
  font-weight: normal;
}
</style>
